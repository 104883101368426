import { Button } from 'antd';
import VCard from "vcard-creator";
import {DownloadOutlined} from "@ant-design/icons";

const downloadAllVCards = async (contacts) => {
    const promises = contacts.map(async (contact) => {
        const vCard = new VCard();
        const { photo, lastName, firstName, mobilePhoneWork, department, position, email, mobilePhonePersonal, emailPersonal } = contact.attributes;
        const departmentName = department?.data?.attributes?.name;
        const url = photo?.data?.attributes?.url;
        const photoUrl = `${process.env.REACT_APP_API_BASE}${url}`;

        if (url) {
            const image = await fetch(photoUrl).then((response) => response.blob());
            const reader = new FileReader();
            reader.readAsDataURL(image);
            return new Promise((resolve) => {
                reader.onload = () => {
                    vCard.addPhoto(reader.result.split(",")[1], photo.data.attributes.mime.split("/")[1]);
                    if (mobilePhoneWork) {
                        vCard.addPhoneNumber(mobilePhoneWork, "TYPE=WORK,TYPE=VOICE,TYPE=PREF");
                    }
                    if (mobilePhonePersonal) {
                        vCard.addPhoneNumber(mobilePhonePersonal, "TYPE=HOME,TYPE=VOICE");
                    }
                    if (departmentName) {
                        vCard.addCompany("Air Moana", departmentName);
                    }
                    if (position) {
                        vCard.addJobtitle(position);
                    }
                    if (email) {
                        vCard.addEmail(email, "TYPE=WORK,TYPE=PREF");
                    }
                    if (emailPersonal) {
                        vCard.addEmail(emailPersonal, "TYPE=HOME");
                    }
                    vCard.addName(lastName, firstName)
                    resolve(vCard);
                };
            });
        } else {
            if (mobilePhoneWork) {
                vCard.addPhoneNumber(mobilePhoneWork, "TYPE=WORK,TYPE=VOICE,TYPE=PREF");
            }
            if (mobilePhonePersonal) {
                vCard.addPhoneNumber(mobilePhonePersonal, "TYPE=HOME,TYPE=VOICE");
            }
            if (departmentName) {
                vCard.addCompany("Air Moana", departmentName);
            }
            if (position) {
                vCard.addJobtitle(position);
            }
            if (email) {
                vCard.addEmail(email, "TYPE=WORK,TYPE=PREF");
            }
            if (emailPersonal) {
                vCard.addEmail(emailPersonal, "TYPE=HOME");
            }
            vCard.addName(lastName, firstName)
            return Promise.resolve(vCard);
        }
    });

    const vcards = await Promise.all(promises);
    const blob = new Blob([vcards.join("\n\n")], { type: "text/x-vcard;charset=utf-8" });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(blob);
    element.download = "contacts.vcf";
    document.body.appendChild(element);
    element.click();
    URL.revokeObjectURL(element.href);
};

const DownloadAllVCardsButton = ({ contacts }) => {
    const handleDownloadAll = () => {
        downloadAllVCards(contacts);
    };
    return (
        <Button type="primary" icon={<DownloadOutlined />} onClick={handleDownloadAll}>
            Télécharger tous les contacts
        </Button>
    );
};

export default DownloadAllVCardsButton;

