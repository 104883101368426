import {
    Avatar,
    Card,
    Col,
    Image,
    message,
    Row, Space,
    Spin, Typography,
} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import apiCall from "../../api/api";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

const {Text} = Typography;

const CheckGp = () => {
    const [contact, setContact] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const {type, id} = useParams();

    const fetchContact = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await apiCall(`${process.env.REACT_APP_API}/${type}/uuid/${id}`);
            setContact(data.data ?? {});
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setIsError(true);
            message.error(error.message);
        }
    }, [id, type]);

    useEffect(() => {
        fetchContact().then((r) => r);
    }, [fetchContact]);

    const isValid = () => {
        if (type === "beneficiaries") {
            return contact.attributes?.isValid;
        } else if (type === "contacts") {
            return contact.attributes?.isGpEligible;
        }
    }

    if (isLoading) {
        return <Row justify="center">
            <Col>
                <Spin/>
            </Col>
        </Row>
    }

    if (isError || !isValid()) {
        return (
            <Row gutter={[10, 10]} justify="center" align="middle" style={{ height: "100vh" }}>
                <Col>
                    <CloseCircleOutlined style={{ fontSize: '50px', color: 'red' }}/>
                </Col>
            </Row>
        )
    }

    return (
        <Row gutter={[10, 10]}>
            <Col md={24} lg={24} sm={24} xs={24} key={`${contact.id}`}>
                <Card className="contact_card" align="center">
                    <Space className="contact_card_space" direction="vertical" align="center">
                        <Image width={300} src="/airmoana.png" />
                        <Avatar shape="square" src={
                            (contact.attributes.photo.data && `${process.env.REACT_APP_API_BASE}${contact.attributes.photo.data.attributes.url}`) ??
                            `${process.env.REACT_APP_AVATAR_API}?name=${contact.attributes.firstName}+${contact.attributes.lastName}&background=1890ff&color=fff`
                        } size={100}/>
                        <Typography.Title level={5} style={{margin: 0, padding: 0}}>
                            {contact.attributes?.firstName} {contact.attributes?.lastName}
                        </Typography.Title>
                        <Text strong>{contact.attributes?.position}</Text>
                        <Text type="secondary" italic>{contact.attributes.department?.data?.attributes.name}</Text>
                        {type === "contacts" && (
                            <Text type="secondary" italic>ID number: {contact.attributes?.identificationNumber}</Text>
                        )}
                        <Text type="secondary" italic>Birthdate: {contact.attributes.birthdate}</Text>
                        <Col>
                            <CheckCircleOutlined style={{ fontSize: '50px', color: 'green' }}/>
                        </Col>
                    </Space>
                </Card>
            </Col>
        </Row>
    )
};

export default CheckGp;
