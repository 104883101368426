import React from 'react';
import {
    Col,
    Divider,
    Layout,
    Row,
    Spin,
    Typography,
} from 'antd';
import AppHeader from './components/AppHeader/AppHeader';
import AppRoutes from './Routes';
import {Footer} from 'antd/es/layout/layout';
import PwaInstallPopupIOS from 'react-pwa-install-ios';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

const {Header, Content} = Layout;
const Text = Typography;

const App = () => {
    const isProduction = process.env.NODE_ENV === 'production';
    const version = packageInfo.version;
    const vCardPath = new RegExp('/vcard/(.*?)');
    const checkGpPath = new RegExp('/check/(.*?)/(.*?)');
    const showHeaderAndFooter = !vCardPath.test(window.location.pathname) && !checkGpPath.test(window.location.pathname);
    return (
        <Layout className="layout">
            <PwaInstallPopupIOS delay={3} lang="fr" appIcon="logo512.png"/>
            {showHeaderAndFooter && <Header><AppHeader/></Header>}
            <CacheBuster
                currentVersion={version}
                isEnabled={isProduction}
                isVerboseMode={false}
                loadingComponent={(
                    <Row justify="center" align="center">
                        <Divider/>
                        <Col justify="center" align="center">
                            <Spin size="small"/>
                            <Text>Chargement...</Text>
                        </Col>
                        <Divider/>
                    </Row>
                )}
                metaFileDirectory={'.'}
            >
                <Content style={{padding: '10px'}}>
                    <AppRoutes/>
                </Content>
            </CacheBuster>
            {showHeaderAndFooter && (
                <Footer style={{textAlign: 'center'}}>
                    Air Moana © 2023 - v{version}
                </Footer>
            )}
        </Layout>

    );
};
export default App;
