import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuthContext} from "../../context/AuthContext";
import {setToken} from "../../helpers";
import {Col, Row, Spin} from "antd";

export default function Callback() {
    let {search} = useLocation()
    const {setUser} = useAuthContext();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        let isSubscribed = true;
        setIsLoading(true);
        const fetchData = async () => {
            const data = await fetch(`${process.env.REACT_APP_API}/auth/microsoft/callback${search}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
            const json = await data.json();
            setIsLoading(false);
            if (data.ok && isSubscribed) {
                setToken(json.jwt);
                setUser(json.user);
                navigate("/me", {replace: true});
            } else {
                console.error('Error fetching data:', json);
            }
        }
        fetchData().catch(error => {
            console.error(error);
            setIsLoading(false);
        });
        return () => isSubscribed = false;
    }, [navigate, search, setUser]);

    return (
        <Row justify="center" align="center">
            <Col>
                {isLoading && <Spin size="large"/>}
            </Col>
        </Row>
    );
}
