import {
    Button,
    Card,
    Col, Divider,
    Row,
    Typography
} from "antd";
import {WindowsOutlined} from '@ant-design/icons';
import React, {Fragment, useState} from "react";
import useScreenSize from "../../hooks/useScreenSize";
const {Text} = Typography;

const SignIn = () => {
    const {isDesktopView} = useScreenSize();
    const [loading, setLoading] = useState(false)
    const enterLoading = () => {
        setLoading(true);
    };
    return (
        <Fragment>
            <Row align="middle">
                <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
                    <Card title="Connexion">
                        <Row justify="center">
                            <Button loading={loading} onClick={() => enterLoading()} href={`${process.env.REACT_APP_API}/connect/microsoft`} type="primary" icon={<WindowsOutlined />} size="Large">
                                Connexion avec Microsoft
                            </Button>
                        </Row>
                        <Divider/>
                        <Text type="secondary">
                            Connexion limitée aux employés et invités Office 365 de SAS NATIREVA. Pour une demande de création de compte veuillez envoyer un courriel à support.dsi@airmoana.pf
                        </Text>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default SignIn;
