import QRCode from 'qrcode';
import {Button, Col, Modal, Row} from 'antd';
import {useEffect, useState, useRef} from 'react';
import {QrcodeOutlined} from "@ant-design/icons";
import React from 'react';

function QrCodeGenerator({uuid}) {
    const url = `https://hono.airmoana.pf/vcard/${uuid}`;
    const [showModal, setShowModal] = useState(false);
    const canvasRef = useRef(null);

    useEffect(() => {
        if (showModal) {
            QRCode.toCanvas(canvasRef.current, url, {
                width: 200,
                margin: 2,
                color: {
                    dark: "#134395"
                }
            });
        }
    }, [showModal, url]);

    return (
        <div>
            <Button icon={<QrcodeOutlined/>} type="primary" onClick={() => setShowModal(true)}>Afficher le code
                QR</Button>
            <Modal footer={false} closable={true} cancelText="Annuler" open={showModal}
                   onCancel={() => setShowModal(false)} onOk={() => setShowModal(false)}>
                <Row justify="center" align="center">
                    <Col justify="center" align="center">
                        <canvas ref={canvasRef} id="qr-canvas" width="200" height="200"></canvas>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}

export default React.memo(QrCodeGenerator);
