import React, { useEffect, useState } from 'react';
import {Button, message} from 'antd';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import apiCall from "../../api/api";
import { getToken } from '../../helpers';
import QRCode from "qrcode";

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        height: '297mm',
        width: '210mm',
    },
    card: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        margin: '20px',
        border: '1px solid black',
        borderRadius: '10px',
        height: '108mm',
        width: '171.2mm',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        backgroundColor: '#134395',
    },
    headerText: {
        fontWeight:'bold',
        width:'100%',
        color:'rgba(255,255,255,0.1)',
        position: 'absolute',
        left:'10px',
        top:'-10px',
        fontSize: '110px',
    },
    body: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginTop: '40px',
    },
    imageLogo: {
        width: 200,
    },
    imagePhoto: {
        width: 110,
        borderRadius: '10px',
        marginLeft:'20px'
    },
    image: {
        width: 50,
    },
    qrCode: {
        width: 120,
        marginRight:'20px'
    },
    personalInfo: {
        flexDirection: 'column',
        marginLeft:'10px',
        fontSize: '12px',
        width: '50%',
        color: '#134395',

    },
    personalInfoName: {
        fontSize: '15px',
        marginBottom:'5px',
        fontWeight: 'bold'
    },
    department:{
        color: 'gray',
        fontSize: '10px',
    },
    footer: {
        fontSize: '10px',
        color: 'gray',
        alignSelf: 'flex-end',
        marginTop: 'auto',
        marginBottom: '10px',
        marginRight: '10px',
    },
});

const GpCard = ({ employee, data, qrCode, type }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.card}>
                <View style={styles.header}>
                    <Image
                        style={styles.imageLogo}
                        src="/airmoana-white.png"
                    />
                </View>
                <View style={styles.body}>
                    {
                        data.attributes?.photo?.data?.attributes?.url ? (
                            <Image
                                style={styles.imagePhoto}
                                src={`${process.env.REACT_APP_API_BASE}${data.attributes.photo.data.attributes.url}`}
                            />
                        ) : (
                            <Image
                                style={styles.imagePhoto}
                                src={`${process.env.REACT_APP_AVATAR_API}?name=${data.attributes?.firstName}+${data.attributes?.lastName}&background=1890ff&color=fff&size=300`}
                            />
                        )
                    }
                    <View style={styles.personalInfo}>
                        <Text style={styles.personalInfoName}>{`${data.attributes?.firstName ?? ''} ${data.attributes?.lastName ?? ''}`}</Text>
                        {type === 'contacts' && data.attributes?.position && (
                            <Text>{`${data.attributes.position}`}</Text>
                        )}
                        {type === 'contacts' && data.attributes?.department && (
                            <Text style={styles.department}>{`${data.attributes.department.data.attributes.name}`}</Text>
                        )}
                        {type === 'beneficiaries' && (
                            <Text>{`Ayant droit de ${employee.contact.firstName} ${employee.contact.lastName}`}</Text>
                        )}
                    </View>
                    {qrCode && (
                        <Image
                            style={styles.qrCode}
                            src={qrCode}
                        />
                    )}
                </View>
                <View style={styles.footer}>
                    <Text>{`${data.attributes?.uuid.toUpperCase() ?? ''}`}</Text>
                </View>
            </View>
        </Page>
    </Document>
);


const DownloadGpCard = ({ employee, uuid, type, messageBtn }) => {
    const [data, setData] = useState(null);
    const [qrCode, setQrCode] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await apiCall(
                    `${process.env.REACT_APP_API}/${type}/uuid/${uuid}?populate=*`,
                    { bearerToken: getToken() },
                );
                if (data && data.attributes) {
                    setData(data);
                    if (uuid) {
                        const canvas = document.createElement('canvas');
                        await QRCode.toCanvas(canvas, `https://hono.airmoana.pf/check/${type}/${uuid}`, {
                            width: 200,
                            margin: 2,
                            color: {
                                dark: "#134395"
                            }
                        });
                        setQrCode(canvas.toDataURL());
                    }
                }
            } catch (error) {
                message.error(`Error while fetching ${type} data!`);
            }
        };
        if (uuid && type) {
            fetchData().catch(error => {
                console.error(error);
            });
        }
    }, [uuid, type]);

    return data && qrCode ? (
        <PDFDownloadLink document={<GpCard data={data} employee={employee} qrCode={qrCode} type={type} />} fileName={`${data.attributes.firstName}-${data.attributes.lastName}-cartegp.pdf`}>
            {({ blob, url, loading, error }) => (loading ? 'Chargement...' : <Button
                type="primary"
            >
                {messageBtn}
            </Button>)}
        </PDFDownloadLink>
    ) : (
        <Button disabled>Chargement...</Button>
    );
};

export default DownloadGpCard;