import React, {useEffect, useState} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {getToken} from './helpers';
import SignIn from './pages/SignIn/SignIn';
import ContactList from './components/ContactList/ContactList';
import Callback from './pages/Callback/Callback';
import ContactDetails from './components/ContactDetails/ContactDetails';
import Vcard from './components/Vcard/Vcard';
import UserProfile from './components/UserProfile/UserProfile'
import CheckGp from "./components/CheckGp/CheckGp";


const AppRoutes = () => {
    const [token, setToken] = useState('');

    useEffect(() => {
        setToken(getToken());
    }, []);

    return (
        <Routes>
            <Route
                path="/"
                element={token ? <ContactList/> : <Navigate to="/signin"/>}
            />
            <Route
                path="/contact/:id"
                element={token ? <ContactDetails/> : <Navigate to="/signin"/>}
            />
            <Route path="/vcard/:id" element={<Vcard/>}/>
            <Route path="/check/:type/:id" element={<CheckGp/>}/>
            <Route path="/signin" element={!token ? <SignIn/> : <Navigate to="/"/>}/>
            <Route path="/callback" element={<Callback/>}/>
            <Route path="/me" element={token ? <UserProfile /> : <Navigate to="/signin" />} />
        </Routes>
    );
};

export default AppRoutes;
