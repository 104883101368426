import {removeToken} from "../helpers";

const apiCall = async (url, options = {}) => {
    const headers = {
        'Content-Type': 'application/json',
        ...options.headers,
        Authorization: options.bearerToken ? `Bearer ${options.bearerToken}` : '',
    };

    try {
        const response = await fetch(url, { method: 'GET', headers });
        if (!response.ok) {
            if (response.status === 401) removeToken();
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
        const responseJson = await response.json()
        if(responseJson.data) {
            return responseJson;
        } else {
            return { data: responseJson };
        }
    } catch (error) {
        throw new Error(`Error while fetching data: ${error.message || 'Unknown error'}`);
    }
};

export default apiCall;
