import {Button, Col, message, Row, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {LeftOutlined, UserOutlined, ProfileOutlined, CloseOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import './AppHeader.css';
import apiCall from "../../api/api";
import {getToken} from "../../helpers";

const AppHeader = () => {
    const [tooltipVisible, setTooltipVisible] = useState(true);
    const [email, setEmail] = useState("");

    const navigate = useNavigate();
    const { user } = useAuthContext();
   /* const handleLogout = () => {
        removeToken();
        navigate("/signin", { replace: true });
    };*/
    const handleProfileRedirect = () => {
        navigate("/me", { replace: true });
    };

    const handleTooltipClose = () => {
        setTooltipVisible(false);
    };

    const tooltipContent = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: "black" }}>Partagez vos idées en remplissant ce formulaire. Développons ensemble notre compagnie.</span>
            <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={handleTooltipClose}
                style={{ marginLeft: 10 }}
            />
        </div>
    );

    const fetchUserProfile = async () => {
        try {
            const { data } = await apiCall(
                `${process.env.REACT_APP_API}/users/me`,
                { bearerToken: getToken() },
            );
            setEmail(data.email);
        } catch (error) {
            message.error('Error while fetching user profile!');
        }
    };

    useEffect(() => {
        fetchUserProfile();
    }, []);

    return (
        <Row className="app-header">
            <Col span={8}>
                <Button
                    size="large"
                    onClick={() => navigate(-1)}
                    icon={<LeftOutlined />}
                    type="link"
                />
            </Col>
            <Col span={8}>
                <Button className="header_space_brand" href="/" type="link">
                    HONO
                </Button>
            </Col>
            {/*<Col span={8} className="right-align">
                {user && (
                    <Button
                        size="large"
                        icon={<LogoutOutlined />}
                        className="auth_button_signUp"
                        type="link"
                        onClick={handleLogout}
                    />
                )}
            </Col>*/}
            <Col span={8} className="right-align">
                {user && email !== "hono.dc@airmoana.pf" && (
                    <>
                        <Tooltip
                            title={tooltipContent}
                            placement="bottomLeft"
                            color="yellow"
                            open={tooltipVisible}
                        >
                            <Button
                                size="large"
                                icon={<ProfileOutlined />}
                                title={"Partagez vos idées d'amélioration en remplissant ce formulaire"}
                                type="link"
                                href={"https://forms.office.com/r/YWYDj16GEA"}
                                target={"_blank"}
                                onClick={handleProfileRedirect}
                            />
                        </Tooltip>
                        <Button
                            size="large"
                            icon={<UserOutlined />}
                            className="auth_button_signUp"
                            type="link"
                            onClick={handleProfileRedirect}
                        />
                    </>
                )}
            </Col>
        </Row>
    );
};

export default AppHeader;
