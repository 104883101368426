import {
    Avatar,
    Card,
    Col,
    message,
    Row,
    Space,
    Spin,
    Typography,
} from "antd";
import React, {useEffect, useState} from "react";
import {getToken} from "../../helpers";
import {useParams} from 'react-router-dom';
import QrCodeGenerator from "../QrCodeGenerator/QrCodeGenerator";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";
import VcardGenerator from "../VcardGenerator/VcardGenerator";
import apiCall from "../../api/api";

const {Text} = Typography;


const ContactDetails = () => {
    const [contact, setContact] = useState({});
    const {id} = useParams();
    useEffect(() => {
        const fetchContact = async () => {
            try {
                const {data} = await apiCall(
                    `${process.env.REACT_APP_API}/contacts/uuid/${id}?populate=*`,
                    {bearerToken: getToken()},
                );
                setContact(data || {});
            } catch (error) {
                message.error('Error while fetching contacts!');
            }
        };
        fetchContact().catch(error => {
            console.error(error);
        });
    }, [id]);


    if (!contact || Object.keys(contact).length === 0) {
        return <Row justify="center">
            <Col>
                <Spin/>
            </Col>
        </Row>
    }

    return (
        <Row gutter={[10, 10]}>
            <Col md={24} lg={24} sm={24} xs={24} key={`${contact.id}`}>
                <Card className="contact_card"
                      align="center">
                    <Space
                        className="contact_card_space"
                        direction="vertical"
                        align="center"
                    >
                        <Avatar shape="square" src={
                            contact.attributes?.photo?.data?.attributes?.url
                                ? `${process.env.REACT_APP_API_BASE}${contact.attributes.photo.data.attributes.url}`
                                : `${process.env.REACT_APP_AVATAR_API}?name=${contact.attributes.firstName}+${contact.attributes.lastName}&background=1890ff&color=fff`
                        } size={200}/>

                        <Typography.Title level={5} style={{margin: 0, padding: 0}}>
                            {contact.attributes?.firstName} {contact.attributes?.lastName}
                        </Typography.Title>
                        <Text strong>{contact.attributes?.position}</Text>
                        <Text type="secondary" italic>{contact.attributes?.department?.data?.attributes?.name}</Text>
                        {contact.attributes?.mobilePhoneWork && (
                        <Text>
                            <a href={`tel:${contact.attributes?.mobilePhoneWork}`}>
                                <PhoneOutlined/> Pro: {contact.attributes?.mobilePhoneWork}
                            </a>
                        </Text>
                        )}
                        {contact.attributes?.email && (
                        <Text>
                            <a href={`mailto:${contact.attributes?.email}`}>
                                <MailOutlined/> Pro: {contact.attributes?.email}
                            </a>
                        </Text>
                        )}
                        {contact.attributes?.mobilePhonePersonal && (
                            <Text>
                                <a href={`tel:${contact.attributes.mobilePhonePersonal}`}>
                                    <PhoneOutlined/> Perso: {contact.attributes.mobilePhonePersonal}
                                </a>
                            </Text>
                        )}
                        {contact.attributes?.emailPersonal && (
                            <Text>
                                <a href={`mailto:${contact.attributes.emailPersonal}`}>
                                    <MailOutlined/> Perso: {contact.attributes.emailPersonal}
                                </a>
                            </Text>
                        )}
                        <Text>{contact.about}</Text>
                        <VcardGenerator uuid={contact.attributes.uuid}/>
                        <QrCodeGenerator uuid={contact.attributes.uuid}/>
                    </Space>
                </Card>
            </Col>
        </Row>
    );
};

export default ContactDetails;
