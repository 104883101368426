import {Button, message} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {ContactsOutlined} from "@ant-design/icons";
import VCard from "vcard-creator";
import apiCall from "../../api/api";
import {getToken} from "../../helpers";

const downloadVcard = (contact, data) => {
    const element = document.createElement("a");
    const file = new Blob([data.toString()], {type: "text/x-vcard;charset=utf-8"});
    element.href = URL.createObjectURL(file);
    element.download = `${contact.attributes?.firstName}-${contact.attributes?.lastName}.vcf`;
    document.body.appendChild(element);
    element.click();
    URL.revokeObjectURL(element.href);
}

const createVCard = async (contact) => {
    const vCard = new VCard();
    const { photo, lastName, firstName, mobilePhoneWork, department, position, email, mobilePhonePersonal, emailPersonal } = contact.attributes;
    const departmentName = department?.data?.attributes?.name;
    const url = photo?.data?.attributes?.url;
    const photoUrl = `${process.env.REACT_APP_API_BASE}${url}`;
    const addContactInfo = () => {
        if (mobilePhoneWork) {
            vCard.addPhoneNumber(mobilePhoneWork, "TYPE=WORK,TYPE=VOICE,TYPE=PREF");
        }
        if (mobilePhonePersonal) {
            vCard.addPhoneNumber(mobilePhonePersonal, "TYPE=HOME,TYPE=VOICE");
        }
        if (departmentName) {
            vCard.addCompany("Air Moana", departmentName);
        }
        if (position) {
            vCard.addJobtitle(position);
        }
        if (email) {
            vCard.addEmail(email, "TYPE=WORK,TYPE=PREF");
        }
        if (emailPersonal) {
            vCard.addEmail(emailPersonal, "TYPE=HOME");
        }
        vCard.addName(lastName, firstName)
        downloadVcard(contact, vCard);
    };
    if (url) {
        const image = await fetch(photoUrl).then((response) => response.blob());
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => {
            vCard.addPhoto(reader.result.split(",")[1], photo.data.attributes.mime.split("/")[1]);
            addContactInfo();
        };
    } else {
        addContactInfo();
    }


};


const VcardGenerator = ({ uuid }) => {
    const [contact, setContact] = useState({});

    const fetchContact = useCallback(async () => {
        try {
            const {data} = await apiCall(
                `${process.env.REACT_APP_API}/contacts/uuid/${uuid}?populate=*`,
                {bearerToken: getToken()},
            );
            setContact(data ?? {});
        } catch (error) {
            message.error(error.message);
        }
    }, [uuid]);

    useEffect(() => {
        fetchContact().then((r) => r);
    }, [fetchContact]);

    return (
        <Button
            type="primary"
            icon={<ContactsOutlined />}
            onClick={() => createVCard(contact)}
        >
            Ajouter aux contacts
        </Button>
    );
};



export default VcardGenerator;

