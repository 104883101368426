import {
    Col,
    message,
    Row,
    Spin,
    List,
    Avatar,
    Button,
    Space,
    Input, Empty
} from "antd";
import React, {useEffect, useState} from "react";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";
import {useNavigate, generatePath} from "react-router-dom";
import apiCall from "../../api/api";
import {getToken} from "../../helpers";
import DownloadAllVCardsButton from "../DownloadAllVcards/DownloadAllVcards";
import {Select} from 'antd';
import {Text} from "@react-pdf/renderer";

const {Option} = Select;

const {Search} = Input;

const ContactList = () => {
    const [contacts, setContacts] = useState([]);
    const [initContacts, setInitContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [departmentFilter, setDepartmentFilter] = useState("All");
    const [islandFilter, setIslandFilter] = useState("All");
    const [email, setEmail] = useState("");

    useEffect(() => {
        let filteredContacts = [...initContacts];
        if (search) {
            filteredContacts = filteredContacts.filter((item) =>
                item.attributes.firstName.toUpperCase().includes(search.toUpperCase()) ||
                item.attributes.lastName.toUpperCase().includes(search.toUpperCase())
            );
        }
        if (departmentFilter !== "All") {
            filteredContacts = filteredContacts.filter((item) => item.attributes.department?.data?.attributes?.name === departmentFilter);
        }
        if (islandFilter !== "All") {
            filteredContacts = filteredContacts.filter((item) => item.attributes.island === islandFilter);
        }
        setContacts(filteredContacts);
    }, [search, departmentFilter, islandFilter, initContacts]);

    const navigate = useNavigate();
    const fetchContacts = async () => {
        setIsLoading(true);
        try {
            const {data} = await apiCall(`${process.env.REACT_APP_API}/contacts?sort=lastName&populate=*&pagination[pageSize]=1000`, {bearerToken: getToken()});
            setInitContacts(data ?? []);
            setContacts(data ?? []);

            const savedDepartmentFilter = localStorage.getItem('departmentFilter');
            const savedIslandFilter = localStorage.getItem('islandFilter');

            if (savedDepartmentFilter) {
                setDepartmentFilter(savedDepartmentFilter);
            }
            if (savedIslandFilter) {
                setIslandFilter(savedIslandFilter);
            }

        } catch (error) {
            message.error('Error while fetching contacts!');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUserProfile = async () => {
        try {
            const { data } = await apiCall(
                `${process.env.REACT_APP_API}/users/me`,
                { bearerToken: getToken() },
            );
            setEmail(data.email);
        } catch (error) {
            message.error('Error while fetching user profile!');
        }
    };

    const onSearch = (e) => {
        setSearch(e);
    };

    const onDepartmentChange = (value) => {
        setDepartmentFilter(value);
        localStorage.setItem('departmentFilter', value);
    };

    const onIslandChange = (value) => {
        setIslandFilter(value);
        localStorage.setItem('islandFilter', value);
    };

    const redirectDetails = (UserId) => {
        const path = generatePath("/contact/:id", {
            id: UserId,
        });
        navigate(path);
    }

    useEffect(() => {
        fetchContacts();
        fetchUserProfile();
    }, []);


    if (isLoading) {
        return (
            <Row justify="center" align="center">
                <Col>
                    <Spin size="large"/>
                </Col>
            </Row>
        )
    }

    return (
        <div
            id="scrollableDiv"
            style={{
                height: '100vh',
                overflow: 'auto',
                padding: '0 16px',
            }}
        >
            <Search style={{padding: '20px'}} placeholder="Rechercher un collaborateur" onSearch={onSearch}
                    enterButton/>
            <Row justify="center" align="center">
                <Col>
                    <Select value={departmentFilter} style={{minWidth: 220}} onChange={onDepartmentChange}>
                        <Option value="All">Tous les services</Option>
                        <Option value="Présidence">Présidence</Option>
                        <Option value="Direction générale">Direction générale</Option>
                        <Option value="Direction des systèmes d'information et de communication">Direction des systèmes d'information et de communication</Option>
                        <Option value="Direction des ressources humaines">Direction des ressources humaines</Option>
                        <Option value="Direction des opérations vols">Direction des opérations vols</Option>
                        <Option value="Direction des opérations au sol">Direction des opérations au sol</Option>
                        <Option value="Direction commerciale">Direction commerciale</Option>
                        <Option value="Direction Marketing">Direction marketing</Option>
                        <Option value="Direction Maintenance et Technique">Direction maintenance et technique</Option>
                        <Option value="Direction Adminitrative & Financière">Direction adminitrative & financière</Option>
                    </Select>
                </Col>
            </Row>
            <Row style={{paddingTop: '20px'}}  justify="center" align="center">
                <Col>
                    <Select value={islandFilter} style={{minWidth: 220}} onChange={onIslandChange}>
                        <Option value="All">Toutes les îles</Option>
                        <Option value="Tahiti">Tahiti</Option>
                        <Option value="Bora Bora">Bora Bora</Option>
                        <Option value="Moorea">Moorea</Option>
                        <Option value="Raiatea">Raiatea</Option>
                        <Option value="Rangiroa">Rangiroa</Option>
                        <Option value="Hiva Oa">Hiva Oa</Option>
                        <Option value="Nuku Hiva">Nuku Hiva</Option>
                    </Select>
                </Col>
            </Row>

            {email !== 'hono.dc@airmoana.pf' && (
                <Row justify="center" align="center">
                    <Col style={{padding: '20px'}}>
                        <DownloadAllVCardsButton contacts={contacts}/>
                    </Col>
                </Row>
            )}

            <List
                dataSource={contacts}
                locale={{emptyText: (<Empty description={false}/>)}}
                renderItem={(item) => (
                    <List.Item
                        key={item.attributes.email}>
                        <List.Item.Meta
                            onClick={() => {
                                redirectDetails(item.attributes.uuid)
                            }}
                            className="itemList"
                            avatar={<Avatar shape="square" src={
                                (item.attributes.photo.data &&
                                    `${process.env.REACT_APP_API_BASE}${item.attributes.photo.data.attributes.url}`) ??
                                `${process.env.REACT_APP_AVATAR_API}?name=${item.attributes.firstName}+${item.attributes.lastName}&background=1890ff&color=fff`
                            } size={100}/>}
                            title={email !== 'hono.dc@airmoana.pf' ? (
                                item.attributes.firstName + ' ' + item.attributes.lastName
                            ) :
                                <>
                                    {item.attributes.firstName + ' ' + item.attributes.lastName}
                                    {item.attributes.isGpEligible ? (
                                        <Text style={{color: "green"}}> // GP Activé</Text>
                                    ) : (
                                        <Text style={{color: "red"}}> // GP Non Activé</Text>
                                    )}
                                </>
                            }
                            description={item.attributes.position}
                        />
                        {email !== 'hono.dc@airmoana.pf' && (
                            <Space direction="vertical">
                                {item.attributes.mobilePhoneWork && (
                                    <Button href={`tel:${item.attributes.mobilePhoneWork}`}
                                            type="primary" icon={<PhoneOutlined/>} size="medium"/>
                                )}
                                {item.attributes.email && (
                                    <Button href={`mailto:${item.attributes.email}`} type="primary"
                                            icon={<MailOutlined/>} size="medium"/>
                                )}
                            </Space>
                        )}
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ContactList;
