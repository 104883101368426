import React, { useEffect, useState } from 'react';
import {Avatar, Button, Card, Divider, List, message, Space, Typography} from 'antd';
import apiCall from "../../api/api";
import {getToken} from '../../helpers';
import {
    CheckCircleFilled,
    CloseCircleFilled,
    LogoutOutlined,
    MailOutlined,
    PhoneOutlined
} from "@ant-design/icons";
import { removeToken } from "../../helpers";
import { useNavigate } from "react-router-dom";
import DownloadGpCard from "../DownloadGpCard/DownloadGpCard";

const { Title, Text } = Typography;


const UserProfile = () => {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    const handleLogout = () => {
        removeToken();
        navigate(0)
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const { data } = await apiCall(
                    `${process.env.REACT_APP_API}/users/me`,
                    { bearerToken: getToken() },
                );
                if (!Array.isArray(data.beneficiaries)) {
                    data.beneficiaries = [];
                }
                setUserData(data ?? {});
            } catch (error) {
                message.error('Error while fetching user profile!');
            }
        };
        fetchUserProfile().catch(error => {
            console.error(error);
        });
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign:'center'}}>

            {userData && userData.contact ? (
                <Card style={{ width:'100%',  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '16px' }}>
                    <Divider style={{fontSize:'20px', fontWeight:'bold'}}>Mes informations</Divider>
                    <Avatar shape="square" src={
                        (userData.contact.photo &&
                            `${process.env.REACT_APP_API_BASE}${userData.contact.photo.url}`) ??
                        `${process.env.REACT_APP_AVATAR_API}?name=${userData.contact.firstName}+${userData.contact.lastName}&background=1890ff&color=fff`
                    } size={100}/>
                    <Title level={3}>{`${userData.contact.firstName} ${userData.contact.lastName}`}</Title>
                    <Space direction="vertical">
                        <Text strong>{userData.contact?.position}</Text>
                        <Text type="secondary" italic>{userData.contact.department?.name}</Text>
                        <Text><a
                            href={`tel:${userData.contact?.mobilePhoneWork}`}><PhoneOutlined/> {userData.contact?.mobilePhoneWork}
                        </a></Text>
                        <Text><a
                            href={`mailto:${userData.contact?.email}`}><MailOutlined/> {userData.contact?.email}
                        </a></Text>
                        <Text>{userData.about}</Text>
                        {userData.contact.isGpEligible ? (
                            <DownloadGpCard uuid={userData.contact.uuid} type="contacts" messageBtn="Ma carte GP" />
                        ) : (
                            <Text>Veuillez contacter le service des ressources humaines pour l'activation de votre carte GP: rh@airmoana.pf</Text>
                        )}
                        <Button icon={<LogoutOutlined/>} onClick={handleLogout} type="primary" danger>Déconnexion</Button>
                    </Space>
                    <Divider style={{fontSize:'20px', marginTop:'50px', fontWeight:'bold'}}>Mes ayants droit</Divider>
                    <List
                        itemLayout="horizontal"
                        dataSource={userData.beneficiaries || []}
                        renderItem={beneficiary => (
                            <List.Item
                                actions={[
                                    <DownloadGpCard employee={userData} uuid={beneficiary.uuid} type="beneficiaries" messageBtn={`Carte GP de ${beneficiary.firstName}`} />
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={beneficiary.isValid ? <CheckCircleFilled style={{ color: 'green', fontSize: '24px' }} /> : <CloseCircleFilled style={{ color: 'red', fontSize: '24px' }} />}
                                    title={`${beneficiary.firstName} ${beneficiary.lastName}`}
                                    description={beneficiary.affiliation}
                                />
                            </List.Item>
                        )}
                    />
                </Card>

            ) : (
                <Card style={{ width:'100%',  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '16px' }}>
                    <Text>Veuillez contacter le service des ressources humaines pour l'activation de votre compte: rh@airmoana.pf</Text>
                    <br/>
                    <Button icon={<LogoutOutlined/>} onClick={handleLogout} type="primary" danger>Déconnexion</Button>
                </Card>
            )}
        </div>
    );
};

export default UserProfile;
